<script setup lang="ts">
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline'
import SolutionsSection from '~/pages/solutions/components/SolutionsSection.vue'
import FooterCta from '~/pages/components/new/FooterCta.vue'
import BlogSection from '~/pages/components/new/landing/BlogSection.vue'
import StatsSection from '~/pages/components/new/StatsSection.vue'


const settings = useLandingPageSettings()


</script>

<template>
    <div>
        <div class="relative isolate px-6 pt-14 lg:px-8">
            <div class="mx-auto max-w-2xl py-12 sm:py-16 lg:py-24">
                <div class="hidden sm:mb-8 sm:flex sm:justify-center">
                    <NuxtLink
                        v-if="settings.eyebrowUrl"
                        :href="settings.eyebrowUrl"
                        target="_blank"
                    >
                        <div
                            class="relative mb-6 rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20 sm:mb-0"
                        >
                            {{ settings.eyebrowText }}
                            <span class="font-semibold text-green-600"><span
                                class="absolute inset-0"
                                aria-hidden="true"
                            />Read more
                                <span aria-hidden="true">&rarr;</span></span>
                        </div>
                    </NuxtLink>
                </div>
                <div class="text-center">
                    <h1
                        class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl"
                    >
                        {{ settings.mainHeroTitle }}
                    </h1>
                    <div class="mt-6 text-lg leading-8 text-gray-600"
                    v-html="settings.mainHeroSubtitle">
                    </div>
                    <div
                        class="mb-10 mt-14 flex items-center justify-center gap-x-6"
                    >
                        <NuxtLink
                            v-if="settings.livePreviewUrl"
                            :href="settings.livePreviewUrl"
                            class="inline-flex items-center gap-x-2 rounded-md bg-green-600 px-5 py-3.5 text-lg font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            Live preview
                            <ArrowTopRightOnSquareIcon
                                class="-mr-0.5 h-6 w-6"
                                aria-hidden="true"
                            />
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </div>

        <SolutionsSection :card="true" />

        <StatsSection />

        <BlogSection />

        <FooterCta :show-second-option="true" />
    </div>
</template>
