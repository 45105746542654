<script setup lang="ts">
import moment from 'moment'
import { graphql } from '~/gql'
import { LandingPageBlogPostsDocument } from '~/gql/graphql'
import Avatar from '~/pages/components/Avatar.vue'

graphql(`

query LandingPageBlogPosts {
    landingPageBlogPosts {
        ...BlogPost
    }
}

`)

const { data: blogPostsData } = await useAsyncQuery(LandingPageBlogPostsDocument)

const posts = computed(() => blogPostsData?.value?.landingPageBlogPosts ?? [])
</script>

<template>
    <div class="bg-white py-24 sm:py-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl text-center">
                <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Insights & Foresights Blog
                </h2>
                <p class="mt-2 text-lg leading-8 text-gray-600">
                    Exploring legislative and regulatory trends, industry shifts, and emerging technologies that are shaping the future of your business.
                </p>
            </div>
            <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                <article v-for="post in posts" :key="post.id" class="flex flex-col items-start justify-between">
                    <div class="relative w-full">
                        <NuxtLink :href="`/about/blog/${post.slug}`">
                            <img
                                :src="post.featureImageUrl ?? '/cartoon.png'" alt=""
                                class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                            >
                            <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                        </NuxtLink>
                    </div>
                    <div class="max-w-xl">
                        <div class="mt-8 flex items-center gap-x-4 text-xs">
                            <time :datetime="post.publishedDate" class="text-gray-500">{{
                                moment(post.publishedDate).format('MMMM D, YYYY') }}</time>
                            <span
                                v-for="category in post.categories" :key="category"
                                class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100 mr-2"
                            >{{
                                category }}</span>
                        </div>
                        <div class="group relative">
                            <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                <NuxtLink :href="`/about/blog/${post.slug}`">
                                    <span class="absolute inset-0" />
                                    {{ post.title }}
                                </NuxtLink>
                            </h3>
                            <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                                {{ post.excerpt }}
                            </p>
                        </div>
                        <div class="relative mt-8 flex items-center gap-x-4">
                            <Avatar
                                v-if="post.author" :photo-url="post.author.avatarUrl" :name="post.author.name"
                                class="h-10 w-10"
                            />
                            <div class="text-sm leading-6">
                                <p class="font-semibold text-gray-900">
                                    <NuxtLink :href="post.author?.href ?? '#'">
                                        <span class="absolute inset-0" />
                                        {{ post.author?.name }}
                                    </NuxtLink>
                                </p>
                                <p class="text-gray-600">
                                    {{ post.author?.role }}
                                </p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </div>
</template>
